import { useState, useEffect, useReducer, useMemo } from "react";
import { CustomTooltip } from "../nexus/nexus.js";
import FileCopyTwoToneIcon from "@mui/icons-material/FileCopyTwoTone";
import FindInPageTwoToneIcon from "@mui/icons-material/FindInPageTwoTone";
import { useLocation } from "react-router-dom";
import VisibilityOff from  "../scenes/VisibilityOff.js";
import VisibilityOn from "../scenes/VisibilityOn.js";
import {
  sortDigitsAlpha,
  sortAlpha,
  setUnique,
  finderSelectStyles,
} from "../nexus/finder.js";
import useColumns from "../hooks/useColumns.js";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import DatePicker from "react-datepicker";

import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useBlockLayout,
  useResizeColumns,
  useRowSelect,
} from "react-table";
import finderStyles from "../styles/finder.module.css";
import { BiReset } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import axios from "axios";
import moment from "moment";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
export default function ReportFinder({ isCopy }) {
  const [reports, setReports] = useState([]);
  const [columns, setColumns] = useState(useColumns({ isCopy }));
  const userToken = useSelector((state) => state.userToken);

  const renewColumns = () => {
    setColumns((prevColumns) =>
      prevColumns.map((column) => ({
        ...column,
        show:
          !isCopy && !column.showInCopy
            ? true // If !isCopy and !showInCopy, set show to true
            : isCopy && !column.showInCopy
            ? false // If isCopy and showInCopy is false, set show to false
            : isCopy
            ? column.show || column.copy // Apply existing logic for isCopy
            : column.copy
            ? false
            : column.show, // Default logic for non-isCopy cases
      }))
    );
  };

  useEffect(() => {
    renewColumns();
    setGlobalFilter("");
  }, [isCopy]);
  const filterInitialState = {
    isOpen: {
      id: false,
      status: false,
      type: false,
      author: false,
      dateFrom: false,
      period: false,
      from: false,
      project: false,
      ragG: false,
      ragO: false,
      ragR: false,
    },
    reportIds: [],
    authors: [],
    projects: [],
    statuses: [],
    types: ["Fortnightly", "Monthly"],
    rags: ["0", "1-4", "5-9", "10-14", "15-20", "21-25", "26-30"],
    id: [],
    createdBy: [],
    projectNames: [],
    dateFrom: null,
    dateTo: null,
    periodFrom: null,
    periodTo: null,
    modFrom: null,
    modTo: null,
  };

  const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case "SET_IS_OPEN":
        return {
          ...state,
          isOpen: {
            ...state.isOpen,
            [payload.type]: payload.value,
          },
        };
      case "RESET_FILTERS":
        const {
          authors,
          projects,
          reportIds,
          statuses,
          ...filteredInitialState
        } = filterInitialState;
        return {
          ...state,
          ...filteredInitialState,
        };

      default:
        return { ...state, [type]: payload };
    }
  };
  const [filterState, dispatch] = useReducer(reducer, filterInitialState);
  const {
    isOpen,
    reportIds,
    authors,
    projects,
    statuses,
    types,
    rags,
    id,
    createdBy,
    projectNames,
    dateFrom,
    dateTo,
    periodFrom,
    periodTo,
    modFrom,
    modTo,
  } = filterState;

  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("finderItems"));
    if (!storedItems) {
      fetchData(); // Perform action when finderItems exist in localStorage
    } else {
      setReports(storedItems);
      setFilterData(storedItems);
    }
  }, []); 

  const fetchData = async () => {
    try {
      // const response = await axios.get("/getAllReports");
      const response = await axios.get("/getAllReports", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const modifiedItems = response.data.map((item) => {
        const { ragCountsComment, workflow, projects, ...rest } = item;

        //const fromDate = moment(item.from, "DD/MM/YYYY");

        const ragGreen = ragCountsComment ? ragCountsComment.Green : 0;
        const ragOrange = ragCountsComment ? ragCountsComment.Orange : 0;
        const ragRed = ragCountsComment ? ragCountsComment.Red : 0;
        const viewTitle = item.title;
        const lastModifiedDate = moment(
          item.lastModified.split(" ")[0],
          "D/M/YYYY"
        ).format("DD/MM/YYYY");
        const projectArray = item.projects
          .filter((project) => project.active && project.name.label !== "Select a project...")
          .map((project) => project.name.label);
        const projectNames = projectArray.join(", ");
        // Create the modified item object
        const modifiedItem = {
          ...rest,
          ragGreen,
          ragOrange,
          ragRed,
          projectNames,
          lastModifiedDate,
          status: workflow ? workflow.currentStatus : "Draft",
          viewTitle,
        };

        return modifiedItem;
      });

      setReports(modifiedItems);
      setFilterData(modifiedItems);
      sessionStorage.setItem("finderItems", JSON.stringify(modifiedItems));
    } catch (error) {
      console.error("Error while fetching items:", error);
    }
  };

  const setFilterData = (items) => {
    const statuses = setUnique(items, "status");

    dispatch({
      type: "statuses",
      payload: statuses.map((status) => status.label),
    });

    const reportIds = sortAlpha(setUnique(items, "id"));
    dispatch({ type: "reportIds", payload: reportIds });

    const authors = setUnique(items, "createdBy");
    dispatch({ type: "authors", payload: sortAlpha(authors) });

    const allProjects = sortDigitsAlpha(
      items.flatMap((item) =>
        item.projectNames.split(",").map((name) => name.trim())
      )
    ).map((project) => ({ label: project, value: project }));

    dispatch({ type: "projects", payload: allProjects });
  };

  const data = useMemo(() => reports, [reports, isCopy]);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 150,
      maxWidth: 400,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    state,
    setGlobalFilter,
    setAllFilters,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }),
        sortBy: [
          {
            id: "from",
            desc: true,
          },
        ],
      },
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    useRowSelect
  );

  const { globalFilter } = state;

  const handleSearch = (e) => {
    setGlobalFilter(e.target.value || undefined);
  };

  const handleHide = (accessor) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.accessor === accessor
          ? { ...column, show: !column.show }
          : column
      )
    );
  };

  const handleUpdate = () => {
    const selectedRows = rows.filter((row) => row.isSelected);
    const selIDs = selectedRows.map((row) => row.original.id);
    alert("Selected IDs:", selIDs);
  };
  const handleRangeValues = (value, prevFilters, isChecked) => {
    const [start, end] = value.split("-");
    const lowerBound = parseInt(start);
    const upperBound = parseInt(end);
    const rangeValues = [];

    for (let i = lowerBound; i <= upperBound; i++) {
      rangeValues.push(i.toString());
    }

    return isChecked
      ? [...prevFilters, ...rangeValues]
      : prevFilters.filter((value) => !rangeValues.includes(value));
  };

  const handleSelect = (columnId, e) => {
    const filters = (prevFilters = []) => {
      const value = e.target.value;
      const isChecked = e.target.checked;

      if (value.includes("-")) {
        return handleRangeValues(value, prevFilters, isChecked);
      }

      return isChecked
        ? [...prevFilters, value]
        : prevFilters.filter((filter) => filter !== value);
    };

    setFilter(columnId, filters);
    dispatch({ type: columnId, payload: filters });
  };

  const handleDateToChange = (columnId, colPart, from, date) => {
    dispatch({ type: colPart, payload: date });
    setFilter(columnId, [from, date]);
  };

  const handleDateChange = (columnId, colPart, colToPart, date) => {
    dispatch({ type: colPart, payload: date });
    if (colToPart != null) {
      dispatch({ type: colToPart, payload: null });
      setFilter(columnId, "");
    }
    setFilter(columnId, [date]);
  };
  const updateFilterData = (data, field) => {
    if (!data) return;

    const filterFields = [
      // { type: "statuses", field: "status" },
      { type: "reportIds", field: "id" },
      { type: "authors", field: "createdBy" },
      { type: "projects", field: "projectNames" },
    ];

    filterFields.forEach((filterField) => {
      if (field !== filterField.field) {
        dispatch({
          type: filterField.type,
          payload: sortAlpha(setUnique(data, filterField.field)),
        });
      }
    });
  };
  const handleMultiChange = (selOptions, field) => {
    dispatch({ type: field, payload: selOptions });
    const values = selOptions.map((item) => item.value);
    setFilter(field, values);
    // let filteredItems = reports;
    // filteredItems = selOptions.length
    //   ? reports.filter((item) => {
    //       return values.includes(item[field]);
    //     })
    //   : reports;
    // updateFilterData(filteredItems, field);
  };
  const handleClearFilters = () => {
    setAllFilters([]);
    dispatch({ type: "RESET_FILTERS" });
    setGlobalFilter("");
  };

  const filterData = [
    {
      accessor: "id",
      label: "ID",
      type: "id",
      data: reportIds,
      control: "select",
      name: "filtersId",
      placeholder: "Select Report Id/s...",
      styles: finderSelectStyles,
      value: id,
    },
    {
      accessor: "reportType",
      label: "Type",
      type: "type",
      data: types,
      control: "check",
    },
    {
      accessor: "createdBy",
      label: "Author",
      type: "author",
      data: authors,
      control: "select",
      name: "filtersAuthor",
      placeholder: "Select Author/s...",
      styles: finderSelectStyles,
      value: createdBy,
    },
    {
      accessor: "from",
      label: "Reporting Period",
      type: "from",
      control: "date",
      start: dateFrom,
      startString: "dateFrom",
      end: dateTo,
      endString: "dateTo",
    },
    {
      accessor: "projectNames",
      label: "Projects",
      type: "project",
      data: projects,
      control: "select",
      name: "filtersProject",
      placeholder: "Select Project/s...",
      styles: finderSelectStyles,
      value: projectNames,
    },

    {
      accessor: "ragGreen",
      label: "RAG ",
      type: "ragG",
      data: rags,
      control: "check",
    },
    {
      accessor: "ragOrange",
      label: "RAG ",
      type: "ragO",
      data: rags,
      control: "check",
    },
    {
      accessor: "ragRed",
      label: "RAG ",
      type: "ragR",
      data: rags,
      control: "check",
    },
    {
      accessor: "status",
      label: "Status",
      type: "status",
      data: statuses,
      control: "check",
    },
    {
      accessor: "lastModifiedDate",
      label: "Date Modified",
      type: "isOpenFrom",
      control: "date",
      start: modFrom,
      startString: "modFrom",
      end: modTo,
      endString: "modTo",
    },
  ];

  return (
    <div className={finderStyles.container}>
      {/* <div className={rfStyles.leftNavBar}>
        <NavBar />
      </div> */}

      <div className={finderStyles.gridContainer}>
        <div className={finderStyles.leftSection}>
          <div
            className={finderStyles.clearFiltersContainer}
            onClick={handleClearFilters}
          >
            <BiReset className={finderStyles.clearFiltersIcon} />
            <span className={finderStyles.clearFiltersText}>Reset Filters</span>
          </div>
          <div className={finderStyles.filterSection}>
            {filterData.map((filter) => {
              return (
                <div className={finderStyles.filter} key={filter.accessor}>
                  {columns.find((column) => column.accessor === filter.accessor)
                    ?.show ? (
                    <>
                      {isOpen[filter.type] ? (
                        <RiArrowDownSLine
                          className={finderStyles.iconFilter}
                          onClick={() =>
                            dispatch({
                              type: "SET_IS_OPEN",
                              payload: { type: filter.type, value: false },
                            })
                          }
                        />
                      ) : (
                        <RiArrowRightSLine
                          className={finderStyles.iconFilter}
                          onClick={() =>
                            dispatch({
                              type: "SET_IS_OPEN",
                              payload: { type: filter.type, value: true },
                            })
                          }
                        />
                      )}
                      <label className={finderStyles.lblFilter}>
                        {filter.label}
                        {filter.type === "ragG" && (
                          <label className={finderStyles.greenBox}></label>
                        )}
                        {filter.type === "ragG" && (
                          <label
                            className={finderStyles.colorBox}
                            style={{ backgroundColor: "green" }}
                          ></label>
                        )}
                        {filter.type === "ragO" && (
                          <label
                            className={finderStyles.colorBox}
                            style={{ backgroundColor: "orange" }}
                          ></label>
                        )}
                        {filter.type === "ragR" && (
                          <label
                            className={finderStyles.colorBox}
                            style={{ backgroundColor: "#FF4500" }}
                          ></label>
                        )}
                      </label>
                    </>
                  ) : (
                    <>
                      <RiArrowRightSLine
                        className={finderStyles.iconFilterHide}
                      />
                      <label className={finderStyles.lblFilterDisabled}>
                        {filter.label}
                        {filter.type === "ragG" && (
                          <label
                            className={finderStyles.colorBox}
                            style={{ backgroundColor: "green" }}
                          ></label>
                        )}
                        {filter.type === "ragO" && (
                          <label
                            className={finderStyles.colorBox}
                            style={{ backgroundColor: "orange" }}
                          ></label>
                        )}
                        {filter.type === "ragR" && (
                          <label
                            className={finderStyles.colorBox}
                            style={{ backgroundColor: "#FF4500" }}
                          ></label>
                        )}
                      </label>
                    </>
                  )}

                  {isOpen[filter.type] ? (
                    <div className={finderStyles.filterContent}>
                      {filter.control === "check" &&
                        filter.data.map((val) => (
                          <Form.Check
                            type="checkbox"
                            label={val}
                            value={val}
                            id={val}
                            onClick={(e) => handleSelect(filter.accessor, e)}
                          />
                        ))}
                      {filter.control === "date" && (
                        <div className={finderStyles.datepickerWrapper}>
                          <DatePicker
                            selected={filter.start}
                            onChange={(date) =>
                              handleDateChange(
                                filter.accessor,
                                filter.startString,
                                filter.endString,
                                date
                              )
                            }
                            className={finderStyles.datePicker}
                            dateFormat="dd/MM/y"
                            placeholderText="Pick date..."
                          />

                          <label>To</label>
                          <DatePicker
                            selected={filter.end}
                            onChange={(date) =>
                              handleDateToChange(
                                filter.accessor,
                                filter.endString,
                                filter.start,
                                date
                              )
                            }
                            minDate={filter.start}
                            dateFormat="dd/MM/y"
                            placeholderText="Pick date..."
                            disabled={filter.start === null}
                            className={finderStyles.datePicker}
                          />
                        </div>
                      )}
                      {filter.control === "select" && (
                        <Select
                          name={filter.name}
                          placeholder={filter.placeHolder}
                          styles={filter.styles}
                          value={filter.value}
                          options={filter.data}
                          onChange={(selOptions) =>
                            handleMultiChange(selOptions, filter.accessor)
                          }
                          isMulti
                        />
                      )}
                    </div>
                  ) : columns.find(
                      (column) => column.accessor === filter.accessor
                    )?.show ? (
                    <VisibilityOn
                      onClick={() => handleHide(filter.accessor)}
                      className={finderStyles.iconColsShow}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => handleHide(filter.accessor)}
                      className={finderStyles.iconColsHide}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={finderStyles.rightSection}>
          <div className={finderStyles.topSection}>
            {!isCopy ? (
              <>
                <FindInPageTwoToneIcon className={finderStyles.finderIcon} />
                <label className={finderStyles.topHeader}>Report Finder</label>
              </>
            ) : (
              <>
                <FileCopyTwoToneIcon className={finderStyles.homeIconHead} />
                <label className={finderStyles.topHeader}>
                  Create from Existing Report{" "}
                </label>
              </>
            )}
            <div className={finderStyles.searchBox}>
              <Form.Control
                type="text"
                placeholder=""
                value={globalFilter || ""}
                onChange={handleSearch}
                className={finderStyles.searchControl}
              />
            </div>
          </div>

          <div className={finderStyles.countSection}>
            {!isCopy &&
              (rows.length === 1
                ? `${rows.length} Result`
                : `${rows.length} Results`)}
          </div>

          <div className={finderStyles.bottomSection}>
            <table {...getTableProps()} className={finderStyles.grid}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <CustomTooltip title="Click to sort">
                          {column.render("Header")}
                        </CustomTooltip>
                        
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <HiSortDescending
                              className={finderStyles.sortIcon}
                            />
                          ) : (
                            <HiSortAscending
                              className={finderStyles.sortIcon}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {reports.length > 0 ? (
                <tbody
                  className={finderStyles.reportContent}
                  {...getTableBodyProps()}
                >
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                rows.length !== 0 && <div className={finderStyles.loaderOverlay}>
                  <div className={finderStyles.loaderContainer}>
                    <ThreeDots color="#186d90" height={150} width={150} />
                  </div>
                </div>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
