export const sortDigitsAlpha = (items) => {
  return Array.from(new Set(items))
    .filter((name) => name !== "")
    .sort((a, b) => {
      if (/^\d/.test(a) && !/^\d/.test(b)) {
        return -1;
      } else if (!/^\d/.test(a) && /^\d/.test(b)) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });
};
export const setUnique = (items, key) => {
  const uniqueValues = [
    ...new Set(
      items.filter((item) => item[key] !== "").map((item) => item[key])
    ),
  ];

  return uniqueValues.map((value) => ({
    label: value,
    value,
  }));
};

export const sortAlpha = (arr) => {
  return arr.sort((a, b) => a.label.localeCompare(b.label));
};
export const finderSelectStyles = {
  control: (provided) => ({
    ...provided,

    paddingBottom: "150px",
    width: "280px",
    border: "none",
  }),
  placeholder: (provided) => ({
    ...provided,
  }),

  dropdownIndicator: (provided) => ({
    ...provided,
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "0",
      height: "0",
      borderLeft: "8px solid transparent",
      borderRight: "8px solid transparent",
      borderTop: "8px solid black",
      cursor: "pointer",
    },
  }),
};
export  const multiValueFilter = (rows, columnId, filterValue) => {
  if (filterValue.length === 0) {
    return rows;
  }

  return rows.filter((row) => {
    const columnValues = String(row.original[columnId]).split(",");
    return columnValues.some((value) => filterValue.includes(value.trim()));
  });
};
