import React, { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

import iconReportCreator from "../images/iconReportCreator.png";
import iconReportFinder from "../images/iconReportFinder.png";
import iconCommentFinder from "../images/iconCF.png";
import iconNoteFinder from "../images/iconNF.png";

import navStyles from "../styles/navBar.module.css";
import * as Cts from "../nexus/constants.js";

import Tooltip from "@mui/material/Tooltip";
import { useSelector } from "react-redux";
import { canCreateReport } from "../nexus/nexus.js";
import ReportPane from "./ReportPane.js";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";

export default function NavBar() {
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.userRole);
  const [selectedNavItem, setSelectedNavItem] = useState("");
  const location = useLocation();
  useEffect(() => {
    setSelectedNavItem(location.pathname);
  }, [location]);
  return (
    <>
      <div className={navStyles.navBar}>
        <Nav defaultActiveKey="/" className="flex-column">
          <Nav.Link
            className={`${
              !canCreateReport.includes(userRole) ? navStyles.disabled : ""
            } ${selectedNavItem === "/createReport" ? navStyles.selected : ""}`}
            onClick={() => setIsPaneOpen(true)}
          >
            <Tooltip title="Report Creator">
              <img src={iconReportCreator} className={navStyles.icons} />
            </Tooltip>
          </Nav.Link>
          <div className={navStyles.hLine}></div>
          <Nav.Link onClick={() => navigate(`/findReport`)}>
            <Tooltip title="Report Finder">
              <img src={iconReportFinder} className={navStyles.icons} />
            </Tooltip>
          </Nav.Link>
          <div className={navStyles.hLine}></div>
          <Nav.Link onClick={() => navigate(`/findComment`)}>
            <Tooltip title="Comment Finder">
              <img src={iconCommentFinder} className={navStyles.icons} />
            </Tooltip>
          </Nav.Link>
          <div className={navStyles.hLine}></div>
          <Nav.Link onClick={() => navigate(`/findNote`)}>
            <Tooltip title="Note Finder">
              <img src={iconNoteFinder} className={navStyles.icons} />
            </Tooltip>
          </Nav.Link>

          <div className={navStyles.hLine}></div>
          <Tooltip title="Home page">
            <OtherHousesIcon
              className={navStyles.homeIcon}
              onClick={() => navigate(`/`)}
            />
          </Tooltip>
        </Nav>
        <div className={navStyles.hLine}></div>
      </div>

      {isPaneOpen && (
        <ReportPane
          isOpen={isPaneOpen}
          onClose={() => setIsPaneOpen(false)}
          reportLinks={Cts.reportLinks}
        />
      )}
    </>
  );
}
