import { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import finderStyles from "../styles/finder.module.css";
import Tooltip from "@mui/material/Tooltip";

export default function useColumnsComment() {
  const navigate = useNavigate();
  const statusColorMap = {
    Green: "#008000",
    Amber: "#FFA500",
    Red: "#FF4500",
  };

  const dateSortFunction = (rowA, rowB, columnId) => {
    const dateA = new Date(
      rowA.values[columnId].split("/").reverse().join("-")
    );
    const dateB = new Date(
      rowB.values[columnId].split("/").reverse().join("-")
    );

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  };

  const multiSelectFilter = (rows, columnId, filterValue) => {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
          filterValue.includes(String(row.original[columnId]))
        );
  };
  const customDateFilter = (rows, columnIds, filterValue) => {
    const filteredRows = rows.filter((row) => {
      const dateValue = row.original[columnIds[0]];

      const [rowDay, rowMonth, rowYear] = dateValue.split("/");
      const rowDate = new Date(`${rowYear}-${rowMonth}-${rowDay}`);

      if (Array.isArray(filterValue) && filterValue.length === 2) {
        const [startDate, endDate] = filterValue;
        const start = new Date(startDate);
        const end = new Date(endDate);

        return rowDate >= start && rowDate <= end;
      } else {
        const [firstDate] = filterValue;
        const start = new Date(firstDate);

        return rowDate >= start;
      }
    });

    return filteredRows;
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filter: multiSelectFilter,
        show: true,
        width: 250,
        // width: "auto",
        Cell: (props) => {
          return <p style={{ fontWeight: 600 }}>{props.value}</p>;
        },
      },
      {
        Header: "Package",
        accessor: "header",
        width: 250,

        filter: multiSelectFilter,
        show: true,
        Cell: (props) => {
          const id = props.row.original.id;

          return (
            <Tooltip title={`View ${id}`}>
              <Nav.Link
                onClick={() => navigate(`/viewComment?ID=${id}`)}
                style={{
                  color: "#0073CF",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onMouseEnter={(e) =>
                  (e.target.style.textDecoration = "underline")
                }
                onMouseLeave={(e) => (e.target.style.textDecoration = "none")}
              >
                <p style={{ fontWeight: 400 }}>{props.value}</p>
              </Nav.Link>
            </Tooltip>
          );
        },
      },

      {
        Header: "RAG",
        accessor: "rag",
        filter: multiSelectFilter,
        show: true,
        width: 120,
        Cell: (props) => {
          return (
            <p
              class={finderStyles.colorBox}
              style={{ backgroundColor: statusColorMap[props.value] }}
            ></p>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filter: multiSelectFilter,
        show: true,
        width: 250,
      },

      {
        Header: "Date Created",
        accessor: "created",
        filter: customDateFilter,
        show: true,
        sortType: dateSortFunction,
        width: 250,
      },
      {
        Header: "Author",
        accessor: "author",
        filter: multiSelectFilter,
        width: 200,
        show: true,
      },
      {
        Header: "Reporting Period",
        accessor: "period",
        filter: customDateFilter,
        width: 180,
        show: true,
      },
      {
        Header: "Report Status",
        accessor: "status",
        filter: multiSelectFilter,
        width: 150,
        show: true,
      },
      {
        Header: "Category",
        accessor: "type",
        filter: multiSelectFilter,
        show: true,
        width: 150,
      },
      {
        Header: "Project",
        accessor: "project",
        filter: multiSelectFilter,
        width: 200,
        show: true,
      },
    ],
    []
  );

  return columns;
}
