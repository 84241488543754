import { useState, useEffect, useReducer, useMemo } from "react";
import { CustomTooltip } from "../nexus/nexus.js";
import FindInPageTwoToneIcon from "@mui/icons-material/FindInPageTwoTone";
import { sortAlpha, setUnique, finderSelectStyles } from "../nexus/finder.js";
import useColumnsComment from "../hooks/useColumnsComment.js";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useBlockLayout,
  useResizeColumns,
} from "react-table";
import * as Cts from "../nexus/constants.js";
import finderStyles from "../styles/finder.module.css";
import VisibilityOff from "../scenes/VisibilityOff.js";
import VisibilityOn from "../scenes/VisibilityOn.js";
import { BiReset } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import axios from "axios";
import moment from "moment";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { ThreeDots } from "react-loader-spinner";
export default function CommentFinder() {
  const [comments, setComments] = useState([]);
  const [columns, setColumns] = useState(useColumnsComment());
  const userToken = useSelector((state) => state.userToken);
  const BoxHeader = ({ backgroundColor }) => {
    if (backgroundColor === "Amber") {
      backgroundColor = "orange ";
    }
    const labelStyle = {
      backgroundColor,
    };
    return (
      <label className={finderStyles.miniColorBox} style={labelStyle}></label>
    );
  };
  const filterInitialState = {
    isOpen: {
      id: false,
      header: false,
      type: false,
      author: false,
      rag: false,
    },
    commentIds: [],
    authors: [],
    projects: [],
    headers: [],
    types: ["Cost", "Time", "Scope", "Stakeholders", "Approvals"],
    rags: ["Green", "Amber", "Red"],
    id: [],
    author: [],
    header: [],
    dateFrom: null,
    dateTo: null,
    periodFrom: null,
    periodTo: null,
    reportStatuses: ["Draft", "Under Review", "Completed"],
    project,
  };

  const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case "SET_IS_OPEN":
        return {
          ...state,
          isOpen: {
            ...state.isOpen,
            [payload.type]: payload.value,
          },
        };
      case "RESET_FILTERS":
        const {
          authors,
          projects,
          headers,
          commentIds,
          ...filteredInitialState
        } = filterInitialState;
        return {
          ...state,
          ...filteredInitialState,
        };

      default:
        return { ...state, [type]: payload };
    }
  };
  const [filterState, dispatch] = useReducer(reducer, filterInitialState);
  const {
    isOpen,
    commentIds,
    authors,
    projects,
    headers,
    types,
    rags,
    id,
    author,
    header,
    dateFrom,
    dateTo,
    periodFrom,
    periodTo,
    reportStatuses,
    project,
  } = filterState;

  useEffect(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("commentItems"));
    if (!storedItems) {
      fetchData(); // Perform action when finderItems exist in localStorage
    } else {
      setComments(storedItems);
      setFilterData(storedItems);
    }
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/getAllComments", {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      const modifiedItems = response.data.map((item) => {
        const category = Cts.categoriesLocal.find((c) => c.id === item.catId);
        const type = category ? category.name : "";

        return {
          id: item.id,
          header: item.header.value,
          type: type,
          author: item.author || "Hari Narayn",
          rag: item.rag.label === "Orange" ? "Amber" : item.rag.label,
          description: item.desc,
          status: item.reportStatus,
          period: item.reportPeriod,
          project: item.projectName,
          created: moment(
            (item.created && item.created.split(" ")[0]) || "01/06/2023",
            "D/M/YYYY"
          )?.format("DD/MM/YYYY"),
        };
      });
      setComments(modifiedItems);
      setFilterData(modifiedItems);
      sessionStorage.setItem("commentItems", JSON.stringify(modifiedItems));
    } catch (error) {
      console.error("Error while retrieving comments:", error);
    }
  };

  const setFilterData = (items) => {
    const headers = setUnique(items, "header");
    dispatch({ type: "headers", payload: headers });

    const commentIds = sortAlpha(setUnique(items, "id"));
    dispatch({ type: "commentIds", payload: commentIds });

    const authors = setUnique(items, "author");
    dispatch({ type: "authors", payload: sortAlpha(authors) });

    const projects = setUnique(items, "project");
    dispatch({ type: "projects", payload: sortAlpha(projects) });
  };

  const data = useMemo(() => comments, [comments]);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 300,
      maxWidth: 400,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    state,
    setGlobalFilter,
    setAllFilters,
  } = useTable(
    {
      columns,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }),
        sortBy: [
          {
            id: "created",
            desc: true,
          },
        ],
      },
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    useResizeColumns
  );

  const { globalFilter } = state;

  const handleSearch = (e) => {
    setGlobalFilter(e.target.value || undefined);
  };

  const handleHide = (accessor) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.accessor === accessor
          ? { ...column, show: !column.show }
          : column
      )
    );
  };

  const handleRangeValues = (value, prevFilters, isChecked) => {
    const [start, end] = value.split("-");
    const lowerBound = parseInt(start);
    const upperBound = parseInt(end);
    const rangeValues = [];

    for (let i = lowerBound; i <= upperBound; i++) {
      rangeValues.push(i.toString());
    }

    return isChecked
      ? [...prevFilters, ...rangeValues]
      : prevFilters.filter((value) => !rangeValues.includes(value));
  };

  const handleSelect = (columnId, e) => {
    const filters = (prevFilters = []) => {
      const value = e.target.value;
      const isChecked = e.target.checked;

      if (value.includes("-")) {
        return handleRangeValues(value, prevFilters, isChecked);
      }

      return isChecked
        ? [...prevFilters, value]
        : prevFilters.filter((filter) => filter !== value);
    };

    console.log(filters);
    setFilter(columnId, filters);
    dispatch({ type: columnId, payload: filters });
  };

  const handleDateToChange = (columnId, colPart, from, date) => {
    dispatch({ type: colPart, payload: date });
    setFilter(columnId, [from, date]);
  };

  const handleDateChange = (columnId, colPart, colToPart, date) => {
    dispatch({ type: colPart, payload: date });
    if (colToPart != null) {
      dispatch({ type: colToPart, payload: null });
      setFilter(columnId, "");
    }
    setFilter(columnId, [date]);
  };

  const handleMultiChange = (selOptions, field) => {
    dispatch({ type: field, payload: selOptions });
    const values = selOptions.map((item) => item.value);
    setFilter(field, values);
  };
  const handleClearFilters = () => {
    setAllFilters([]);
    dispatch({ type: "RESET_FILTERS" });
    setGlobalFilter("");
  };

  const filterData = [
    {
      accessor: "id",
      label: "ID",
      type: "id",
      data: commentIds,
      control: "select",
      name: "filtersId",
      placeholder: "Select Report Id/s...",
      styles: finderSelectStyles,
      value: id,
    },
    {
      accessor: "header",
      label: "Package",
      type: "header",
      data: headers,
      control: "select",
      name: "filtersHeader",
      placeholder: "Select Header/s...",
      styles: finderSelectStyles,
      value: header,
    },

    {
      accessor: "rag",
      label: "RAG",
      type: "rag",
      data: rags,
      control: "checkRag",
    },
    {
      accessor: "created",
      label: "Date Created",
      type: "created",
      control: "date",
      start: dateFrom,
      startString: "dateFrom",
      end: dateTo,
      endString: "dateTo",
    },
    {
      accessor: "author",
      label: "Author",
      type: "author",
      data: authors,
      control: "select",
      name: "filtersAuthor",
      placeholder: "Select Author/s...",
      styles: finderSelectStyles,
      value: author,
    },
    {
      accessor: "period",
      label: "Reporting Period",
      type: "period",
      control: "date",
      start: periodFrom,
      startString: "periodFrom",
      end: periodTo,
      endString: "periodTo",
    },
    {
      accessor: "status",
      label: "Report Status",
      type: "period",
      data: reportStatuses,
      control: "check",
    },
    {
      accessor: "type",
      label: "Category",
      type: "type",
      data: types,
      control: "check",
    },
    {
      accessor: "project",
      label: "Project",
      type: "project",
      data: projects,
      control: "select",
      name: "filtersProject",
      placeholder: "Select Project/s...",
      styles: finderSelectStyles,
      value: project,
    },
  ];

  return (
    <div className={finderStyles.container}>
      <div className={finderStyles.gridContainer}>
        <div className={finderStyles.leftSection}>
          <div
            className={finderStyles.clearFiltersContainer}
            onClick={handleClearFilters}
          >
            <BiReset className={finderStyles.clearFiltersIcon} />
            <span className={finderStyles.clearFiltersTextComment}>
              Reset Filters
            </span>
          </div>
          <div className={finderStyles.filterSection}>
            {filterData.map((filter) => {
              return (
                <div className={finderStyles.filter} key={filter.accessor}>
                  {columns.find((column) => column.accessor === filter.accessor)
                    ?.show ? (
                    <>
                      {isOpen[filter.type] ? (
                        <RiArrowDownSLine
                          className={finderStyles.iconFilter}
                          onClick={() =>
                            dispatch({
                              type: "SET_IS_OPEN",
                              payload: { type: filter.type, value: false },
                            })
                          }
                        />
                      ) : (
                        <RiArrowRightSLine
                          className={finderStyles.iconFilter}
                          onClick={() =>
                            dispatch({
                              type: "SET_IS_OPEN",
                              payload: { type: filter.type, value: true },
                            })
                          }
                        />
                      )}
                      <label className={finderStyles.lblFilter}>
                        {filter.label}
                      </label>
                    </>
                  ) : (
                    <>
                      <RiArrowRightSLine
                        className={finderStyles.iconFilterHide}
                      />
                      <label className={finderStyles.lblFilterDisabled}>
                        {filter.label}
                      </label>
                    </>
                  )}

                  {isOpen[filter.type] ? (
                    <div className={finderStyles.filterContent}>
                      {filter.control === "check" &&
                        filter.data.map((val) => (
                          <Form.Check
                            type="checkbox"
                            label={val}
                            value={val}
                            id={val}
                            onClick={(e) => handleSelect(filter.accessor, e)}
                          />
                        ))}
                      {filter.control === "checkRag" &&
                        filter.data.map((val) => (
                          <div className={finderStyles.ragFilter}>
                            <Form.Check
                              type="checkbox"
                              label={val}
                              value={val}
                              id={val}
                              onClick={(e) => handleSelect(filter.accessor, e)}
                            />
                            <BoxHeader backgroundColor={val} />
                          </div>
                        ))}
                      {filter.control === "date" && (
                        <div className={finderStyles.datepickerWrapper}>
                          <DatePicker
                            selected={filter.start}
                            onChange={(date) =>
                              handleDateChange(
                                filter.accessor,
                                filter.startString,
                                filter.endString,
                                date
                              )
                            }
                            className={finderStyles.datePicker}
                            dateFormat="dd/MM/y"
                            placeholderText="Pick date..."
                          />

                          <label>To</label>
                          <DatePicker
                            selected={filter.end}
                            onChange={(date) =>
                              handleDateToChange(
                                filter.accessor,
                                filter.endString,
                                filter.start,
                                date
                              )
                            }
                            minDate={filter.start}
                            dateFormat="dd/MM/y"
                            placeholderText="Pick date..."
                            disabled={filter.start === null}
                            className={finderStyles.datePicker}
                          />
                        </div>
                      )}
                      {filter.control === "select" && (
                        <Select
                          name={filter.name}
                          placeholder={filter.placeHolder}
                          styles={filter.styles}
                          value={filter.value}
                          options={filter.data}
                          onChange={(selOptions) =>
                            handleMultiChange(selOptions, filter.accessor)
                          }
                          isMulti
                        />
                      )}
                    </div>
                  ) : columns.find(
                      (column) => column.accessor === filter.accessor
                    )?.show ? (
                    <VisibilityOn
                      onClick={() => handleHide(filter.accessor)}
                      className={finderStyles.iconColsShow}
                    />
                  ) : (
                    <VisibilityOff
                      onClick={() => handleHide(filter.accessor)}
                      className={finderStyles.iconColsHide}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div>
        <div className={finderStyles.rightSection}>
          <div className={finderStyles.topSection}>
            <FindInPageTwoToneIcon className={finderStyles.finderIconComment} />
            <label className={finderStyles.topHeaderComment}>
              Comment Finder
            </label>
            <div className={finderStyles.searchBox}>
              <Form.Control
                type="text"
                placeholder=""
                value={globalFilter || ""}
                onChange={handleSearch}
                className={finderStyles.searchControl}
              />
            </div>
          </div>
          <div className={finderStyles.countSectionComment}>
            {rows.length === 1
              ? `${rows.length} Result`
              : `${rows.length} Results`}
          </div>

          <div className={finderStyles.bottomSection}>
            <table {...getTableProps()} className={finderStyles.grid}>
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        <CustomTooltip title="Click to sort">
                          {column.render("Header")}
                        </CustomTooltip>
                       
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <HiSortDescending
                              className={finderStyles.sortIcon}
                            />
                          ) : (
                            <HiSortAscending
                              className={finderStyles.sortIcon}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              {comments.length > 0 ? (
                <tbody
                  className={finderStyles.reportContent}
                  {...getTableBodyProps()}
                >
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              ) : (
                rows.length !== 0 && <div className={finderStyles.loaderOverlay}>
                  <div className={finderStyles.loaderContainer}>
                    <ThreeDots color="#186d90" height={150} width={150} />
                  </div>
                </div>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
