import React, { useEffect, useState } from "react";
import * as Cts from "../nexus/constants.js";
import homeStyles from "../styles/home.module.css";
import { useNavigate } from "react-router-dom";
import ReportPane from "./ReportPane.js";
import { useSelector } from "react-redux";
import { canCreateReport } from "../nexus/nexus.js";
import Button from "react-bootstrap/Button";
import Link from "@mui/material/Link";
export default function Home() {
  const [isPaneOpen, setIsPaneOpen] = useState(false);

  const userName = useSelector((state) => state.value);
  const userRole = useSelector((state) => state.userRole);

  const userFirstName = userName.split(" ")[0];
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "LXRP Management Dashboard Tool";
  }, []);

  return (
    <div className={homeStyles.home}>
      {userRole !== Cts.noAccess ? (
        <div className={homeStyles.head}>
          Hi {userFirstName}
          {Cts.homeHead}
        </div>
      ) : (
        <div className={homeStyles.naContainer}>
          <div className={homeStyles.naMessage}>
            <h2>
              You currently lack access to this Dashboard. Kindly reach out to
              the BI & I team through&nbsp;
              <Link
                href="https://levelcrossings.atlassian.net/servicedesk/customer/portal/4/group/67/create/192"
                underline="always"
              >
                a service request
              </Link>
              &nbsp;for access.
            </h2>
          </div>
        </div>
      )}

      {userRole !== Cts.noAccess && (
        <div className={homeStyles.homeContainer}>
          {Cts.homePageLinks.map((pageLink) => {
            return (
              <>
                <div
                  className={`${homeStyles.cardContainer} ${pageLink.class} ${
                    !canCreateReport.includes(userRole) && pageLink.privileged
                      ? homeStyles.disabled
                      : ""
                  }`}
                  title={
                    !canCreateReport.includes(userRole) && pageLink.privileged
                      ? pageLink.visitorMsg
                      : pageLink.sub
                  }
                  onClick={
                    pageLink.setPane
                      ? () => setIsPaneOpen(true)
                      : () => navigate(`/${pageLink.path}`)
                  }
                >
                  <div className={homeStyles.iconBox}>{pageLink.icon} </div>
                  <div className={homeStyles.cardText}>
                    <Button className={homeStyles.cardHead} variant="primary">
                      {pageLink.head}
                    </Button>
                    <div className={homeStyles.cardSub}>{pageLink.sub}</div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
      {isPaneOpen && (
        <ReportPane
          isOpen={isPaneOpen}
          onClose={() => setIsPaneOpen(false)}
          reportLinks={Cts.reportLinks}
        />
      )}
    </div>
  );
}