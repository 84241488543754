import React, { useEffect, useState } from "react";
import vcStyles from "../styles/viewComment.module.css";
import CommentDetail from "./CommentDetail";
import { useSelector } from "react-redux";
import { canEditComment } from "../nexus/nexus.js";
import { useLocation } from "react-router-dom";
import axios from "axios";

function ViewComment() {
  const location = useLocation();
  const [comment, setComment] = useState(null);
  const [notes, setNotes] = useState(null);
  const [commentMeta, setCommentMeta] = useState(null);
  const userRole = useSelector((state) => state.userRole);
  const currentUser = useSelector((state) => state.value);
  const userToken = useSelector((state) => state.userToken);
  let currentUserName = "";
  if (currentUser) {
    currentUserName = currentUser.split(" (")[0];
  }

  useEffect(() => {
    const fetchSelectedComment = async () => {
      const queryParams = new URLSearchParams(location.search);
      let theToken = userToken;
      if (!theToken) {
        if (queryParams.has("cid")) {
          theToken = queryParams.get("cid");
        }
      }
      if (queryParams.has("ID")) {
        try {
          const cid = queryParams.get("ID");
          const response = await axios.get("/getCommentById", {
            params: {
              id: cid,
              reportId: cid.split("-")[0],
            },
            headers: {
              Authorization: `Bearer ${theToken}`,
            },
          });

          setComment(response.data.comment);
          setCommentMeta(response.data.commentMeta);
        } catch (error) {
          console.error("Error while retrieving report:", error);
        }
      }
    };

    fetchSelectedComment();
  }, []);

  return (
    <div className={vcStyles.viewComment}>
      {comment && (
        <CommentDetail
          currentComment={comment}
          view={true}
          user={currentUserName && currentUserName}
          isPage={true}
          canEdit={canEditComment.includes(userRole) && userRole.length > 0}
          commentMeta={commentMeta}
          isPackageLocked={commentMeta.isPackageLocked}
        />
      )}
    </div>
  );
}
export default ViewComment;
