import iconReportCreator from "../images/iconReportCreator.png";
import iconReportFinder from "../images/iconReportFinder.png";
import iconCommentFinder from "../images/iconCommentFinder.png";
import iconNoteFinder from "../images/iconNoteFinder.png";
import iconPowerBI from "../images/iconPowerBI.png";

import homeStyles from "../styles/home.module.css";
import slideStyles from "../styles/slide.module.css";
export const idFormat = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
export const homeHead = ", what would you like to do today?";
export const reportEditorHead = "Report Editor | LXRP ";
export const reportTitle = "LXRP: Fortnightly Report Period";
export const reportViewHead = "Report Viewer | LXRP ";
export const reportEditorPlaceholder = "Enter Title Here";
export const prTitlePlaceHolder = "Select project...";
export const prClassificationPlaceHolder = "Select Classification...";
export const cmDescPlaceHolder = "Enter description";
export const cmHeaderPlaceHolder = "Select a package...";
export const txtCreateNewReport = "Create New Report";
export const txtCreateFromExReport = "Create from Existing Report";
export const txtCreateNewReportSub =
  "Create a brand new report without existing content.";
export const txtCreateFromExReportSub =
  "Use the Report Finder to create a copy of an existing report.";
export const iconWidthHome = "250";
export const iconHeightHome = "215";
export const iconWidth = "60";
export const iconHeight = "60";
export const iconWidthLg = "200";
export const iconHeightLg = "200";
export const homeTileWidth = "300";
export const homeTileHeight = "285";
export const logoWidth = "30";
export const expirationTime = "1h";
export const logoHeight = "30";
export const iconWidthSm = "35";
export const iconHeightSm = "35";
export const noAccess = "No Access";
export const ragOptions = [
  { value: "#008000", label: "Green" },
  { value: "#FFA500", label: "Amber" },
  { value: "#FF4500", label: "Red" },
];
export const categoriesLocal = [
  {
    id: "CT01",
    name: "Cost",
    rag: "#008000",
  },
  {
    id: "CT02",
    name: "Time",
    rag: "#008000",
  },
  {
    id: "CT03",
    name: "Scope",
    rag: "#008000",
  },
  {
    id: "CT04",
    name: "Stakeholders",
    rag: "#008000",
  },
  {
    id: "CT05",
    name: "Approvals",
    rag: "#008000",
  },
];
export const photoUrl = "https://graph.microsoft.com/v1.0/me/photo/$value";

export const allowedDates = [
  "2023-01-12",
  "2023-01-26",
  "2023-02-16",
  "2023-03-02",
  "2023-03-17",
  "2023-03-31",
  "2023-04-14",
  "2023-04-25",
  "2023-05-16",
  "2023-06-01",
  "2023-06-16",
  "2023-06-30",
  "2023-07-14",
  "2023-08-01",
  "2023-08-17",
  "2023-08-31",
  "2023-09-14",
  "2023-10-02",
  "2023-10-17",
  "2023-11-12",
  "2023-11-16",
  "2023-12-01",
  "2023-12-15",
];
export const categories = [
  { name: "Cost" },
  { name: "Time" },
  { name: "Scope" },
  { name: "Stakeholders" },
  { name: "Approvals" },
];
export const reportLinks = [
  {
    head: "Create New Report",
    sub: "Create a brand new report without existing content",
    path: "createReport",
    class: slideStyles.contentBoxIndNR,
  },
  {
    head: "Create from Existing Report",
    sub: "Use the Report Finder to create copy of an existing report",
    path: "copyReport",
    class: slideStyles.contentBoxIndER,
  },
];
export const homePageLinks = [
  {
    head: "Report Creator",
    sub: "Create a new report from scratch or create from an existing report",
    path: "createReport",
    visitorMsg: "You need admin or owner access to create a report",

    icon: <img src={iconReportCreator} className={homeStyles.tileImage} />,
    class: homeStyles.contentBoxIndRC,
    setPane: true,
    privileged: true,
  },
  {
    head: "Report Finder",
    sub: "Manage and search for existing reports",
    path: "findReport",
    icon: (
      <img
        className={`${homeStyles.tileImage} ${homeStyles.exReportImg}`}
        src={iconReportFinder}
      />
    ),
    class: homeStyles.contentBoxIndRF,
    setPane: false,
    privileged: false,
  },
  {
    head: "Comment Finder",
    sub: "Manage and search for comments across any report",
    path: "findComment",
    icon: <img src={iconCommentFinder} className={homeStyles.tileImage} />,
    class: homeStyles.contentBoxIndCF,
    setPane: false,
    privileged: false,
  },
  {
    head: "Note Finder",
    sub: "Manage and search for notes across any report",
    path: "findNote",
    icon: <img src={iconNoteFinder} className={homeStyles.tileImage} />,
    class: homeStyles.contentBoxIndCF,
    setPane: false,
    privileged: false,
  },
  {
    head: "Published Reports",
    sub: "Opens a web browser to view reports on Power BI",
    path: "publishedReports",
    icon: <img src={iconPowerBI} className={homeStyles.tileImage} />,
    class: homeStyles.contentBoxIndPR,
    setPane: false,
    privileged: false,
  },
];
export const reportTitleChar = 41;
export const prTitleChar = 38;
