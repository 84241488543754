import React from "react";
import Tooltip from "@mui/material/Tooltip";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

const VisibilityOn = ({ onClick, className }) => (
  <Tooltip title="Hide column/disable filter">
    <VisibilityTwoToneIcon onClick={onClick} className={className} />
  </Tooltip>
);

export default VisibilityOn;
