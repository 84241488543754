import { useMemo, useContext } from "react";
import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import finderStyles from "../styles/finder.module.css";
import { reportDispatchContext } from "../components/Report";

export default function useColumnsLinker({
  type,
  parentId,
  linkedComments,
  userToken,
}) {
  const dispatchReport = useContext(reportDispatchContext);
  const statusColorMap = {
    Green: "#008000",
    Amber: "#FFA500",
    Red: "#FF4500",
  };

  const dateSortFunction = (rowA, rowB, columnId) => {
    const dateA = new Date(
      rowA.values[columnId].split("/").reverse().join("-")
    );
    const dateB = new Date(
      rowB.values[columnId].split("/").reverse().join("-")
    );

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  };

  const multiSelectFilter = (rows, columnId, filterValue) => {
    return filterValue.length === 0
      ? rows
      : rows.filter((row) =>
          filterValue.includes(String(row.original[columnId]))
        );
  };
  const customDateFilter = (rows, columnIds, filterValue) => {
    const filteredRows = rows.filter((row) => {
      const dateValue = row.original[columnIds[0]];

      const [rowDay, rowMonth, rowYear] = dateValue.split("/");
      const rowDate = new Date(`${rowYear}-${rowMonth}-${rowDay}`);

      if (Array.isArray(filterValue) && filterValue.length === 2) {
        const [startDate, endDate] = filterValue;
        const start = new Date(startDate);
        const end = new Date(endDate);

        return rowDate >= start && rowDate <= end;
      } else {
        const [firstDate] = filterValue;
        const start = new Date(firstDate);

        return rowDate >= start;
      }
    });

    return filteredRows;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Select",
        id: "selection",
        width: 80,
        Cell: ({ row }) => {
          return (
            row.original.linked !== "Parent" &&
            row.original.linked !== "Child" && (
              <div>
                <Form.Check
                  type="checkbox"
                  checked={row.isSelected}
                  onChange={() => row.toggleRowSelected()}
                  custom
                  label=""
                />
              </div>
            )
          );
        },
      },
      {
        Header: "ID",
        accessor: "id",
        filter: multiSelectFilter,
        show: true,
        width: 250,
        // width: "auto",
        Cell: (props) => {
          return (
            <Nav.Link href={`/viewComment?ID=${props.value}&cid=${userToken}`}>
              <p
                style={{
                  fontWeight: 400,
                  textDecoration: "underline",
                  color: "blue",
                  cursor: "pointer",
                }}
              >
                {props.value}
              </p>
            </Nav.Link>
          );
        },
      },
      {
        Header: "Link Type",
        accessor: "linked",
        filter: multiSelectFilter,
        show: true,
        width: 120,
        Cell: (props) => {
          return <p>{props.value}</p>;
        },
      },
      {
        Header: "Package",
        accessor: "header",
        filter: multiSelectFilter,
        show: true,
        width: 250,
        Cell: (props) => {
          return <p>{props.value}</p>;
        },
      },
      {
        Header: "RAG",
        accessor: "rag",
        filter: multiSelectFilter,
        show: true,
        width: 120,
        Cell: (props) => {
          return (
            <p
              class={finderStyles.colorBox}
              style={{ backgroundColor: statusColorMap[props.value] }}
            ></p>
          );
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filter: multiSelectFilter,
        show: true,
        width: 250,
      },
      {
        Header: "Date Created",
        accessor: "created",
        filter: customDateFilter,
        show: true,
        sortType: dateSortFunction,
        width: 190,
      },
      {
        Header: "Author",
        accessor: "author",
        filter: multiSelectFilter,
        width: 200,
        show: true,
      },

      {
        Header: "Reporting Period",
        accessor: "period",
        filter: customDateFilter,
        width: 180,
        show: true,
      },
      {
        Header: "Report Status",
        accessor: "status",
        filter: multiSelectFilter,
        width: 150,
        show: true,
      },
      {
        Header: "Category",
        accessor: "type",
        filter: multiSelectFilter,
        show: true,
        width: 150,
      },
      {
        Header: "Project",
        accessor: "project",
        filter: multiSelectFilter,
        width: 200,
        show: true,
      },
      {
        Header: "",
        accessor: "projectName",
        show: false,
        width: 0,
      },
      {
        Header: "",
        accessor: "reportTitle",
        show: false,
        width: 0,
      },
    ],
    [linkedComments]
  );

  return columns;
}
