import { useState, useEffect, useReducer, useContext, useMemo } from "react";
import { MdLink, MdLinkOff } from "react-icons/md";
import DatasetLinkedTwoToneIcon from "@mui/icons-material/DatasetLinkedTwoTone";
import { reportContext, reportDispatchContext } from "./Report";
import Modal from "react-bootstrap/Modal";
import { CustomTooltip } from "../nexus/nexus.js";
import { sortAlpha, setUnique, finderSelectStyles } from "../nexus/finder.js";
import useColumnsLinker from "../hooks/useColumnsLinker.js";
import { HiSortAscending, HiSortDescending } from "react-icons/hi";
import DatePicker from "react-datepicker";
import CircularProgress from "@mui/material/CircularProgress";
import { getCurrentTime } from "../nexus/nexus.js";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  useSortBy,
  useBlockLayout,
  useResizeColumns,
  useRowSelect,
} from "react-table";
import { useSelector, useDispatch } from "react-redux";
import * as Cts from "../nexus/constants.js";
import finderStyles from "../styles/finder.module.css";
import linkerStyles from "../styles/commentLinker.module.css";
import VisibilityOff from "../scenes/VisibilityOff";
import VisibilityOn from "../scenes/VisibilityOn";
import { BiReset } from "react-icons/bi";
import { RiArrowDownSLine, RiArrowRightSLine } from "react-icons/ri";
import axios from "axios";
import moment from "moment";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { ThreeDots } from "react-loader-spinner";
import { setCommentLinkings, setCommentUnlinkings } from "../store/store";
export default function CommentLinker(props) {
  const [comments, setComments] = useState([]);
  const dispatchStore = useDispatch();
  const handleSelectAllRows = () => {
    toggleAllRowsSelected();
  };
  const {
    type,
    parentId,
    title,
    linkedComms,
    handleClose,
    show,
    setShow,
    view,
    ragData,
    historyData,
    updateMetaComment,
    isPage,
    user,
  } = props;

  const report = useContext(reportContext);
  const dispatchReport = useContext(reportDispatchContext);
  const [allComments, setAllComments] = useState(null);
  const [history, setHistory] = useState(historyData);

  const cmLinkings = useSelector((state) => state.commentLinkings);
  const foreignUnlinks = useSelector((state) => state.commentUnlinkings);
  const [linkedComments, setLinkedComments] = useState(linkedComms);
  const userToken = useSelector((state) => state.userToken);
  const BoxHeader = ({ backgroundColor }) => {
    if (backgroundColor === "Amber") {
      backgroundColor = "orange ";
    }
    const labelStyle = {
      backgroundColor,
    };
    return (
      <label className={finderStyles.miniColorBox} style={labelStyle}></label>
    );
  };
  const [columns, setColumns] = useState(
    useColumnsLinker({ type, parentId, linkedComments, userToken })
  );
  const getColorForVal = (val) => {
    switch (val) {
      case "Related":
        return "#ffffcc";
      case "Parent":
        return "#ADD8E6";
      case "Child":
        return "#cefad0";
      default:
        return "#ffffff";
    }
  };

  const checkboxHeaderObj = {
    Header: (
      <div className={linkerStyles.selectAllCheckbox}>
        <Form.Check
          type="checkbox"
          onClick={() => toggleAllRowsSelected()}
          custom
          label=""
        />
      </div>
    ),
    id: "selection2",
    width: 70,
    Cell: ({ row }) => {
      return (
        <div>
          <Form.Check
            type="checkbox"
            checked={row.isSelected}
            onChange={() => row.toggleRowSelected()}
            custom
            label=""
          />
        </div>
      );
    },
  };
  const [checkboxHeader, setCheckboxHeader] = useState(checkboxHeaderObj);

  useEffect(() => {
    if (type === "comment") {
      if (allComments) {
        fetchData(allComments, linkedComments);
      }
    } else {
      fetchData(flattenComments(report), linkedComments);
    }
  }, [linkedComments]);
  const fetchAllComments = async () => {
    const comments = await getAllcomments();
    setAllComments(comments.filter((item) => item.id !== parentId));
    fetchData(comments, linkedComments);
  };
  useEffect(() => {
    if (type === "comment") {
      fetchAllComments();
    }
  }, []);

  const getAllcomments = async () => {
    const response = await axios.get("/getAllComments", {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    });
    return response.data;
  };

  const handleModalClose = () => {
    setShow(false);
    handleClose();
  };

  const handleUpdate = (link) => {
    let updatedHistory = [];
    const selectedRows = rows.filter((row) => row.isSelected);
    const selComments = selectedRows.map((row) => row.original.id);
    const time = getCurrentTime();
    if (type === "note") {
      const updatedComments = link
        ? [...new Set([...linkedComments, ...selComments])]
        : linkedComments.filter((cm) => !selComments.includes(cm));
      setLinkedComments(updatedComments);
      const action = link ? "linked the note " : "unlinked the note ";
      selComments.forEach(async (cid) => {
        const [reportId, projectId, categoryId] = cid.split("-");
        await dispatchReport({
          type: "UPDATE_NOTE_WITH_HISTORY",
          payload: {
            id: parentId,
            key: "comments",
            value: updatedComments,
            cid: cid,
            prId: `${reportId}-${projectId}`,
            catId: categoryId,
            action: action,
          },
        });
      });

      toggleAllRowsSelected(false);
      const checkboxHeaderObj = {
        Header: (
          <div className={linkerStyles.selectAllCheckbox}>
            <Form.Check
              type="checkbox"
              onClick={() => toggleAllRowsSelected()}
              custom
              label=""
              check={false}
            />
          </div>
        ),
        id: "selection2",
        width: 70,
        Cell: ({ row }) => {
          return (
            <div>
              <Form.Check
                type="checkbox"
                checked={row.isSelected}
                onChange={() => row.toggleRowSelected()}
                custom
                label=""
              />
            </div>
          );
        },
      };
      setCheckboxHeader(checkboxHeaderObj);
    } else {
      let resultArray = [],
        foreignArray = [],
        historyArray = [],
        historyObject = "",
        linkingsArray = [],
        updatedLinkedComms = [];

      if (link) {
        selectedRows.forEach((row) => {
          let itemExists = false;
          if (linkedComments?.length) {
            itemExists = linkedComments.some(
              (item) => item.id === row.original.id
            );
          }
          if (!itemExists) {
            const sourceObject = {
              id: row.original.id,
              type: "link",
              time: time,
            };
            const historyObject = {
              time: time,
              editor: user,
              action: `linked the comment -  `,
              value: row.original.id,
            };
            const linkingObject = {
              source: parentId,
              target: row.original.id,
              time: time,
              type: "link",
            };
            linkingsArray.push(linkingObject);
            resultArray.push(sourceObject);
            if (resultArray.length) {
              dispatchStore(
                setCommentLinkings([...cmLinkings, ...linkingsArray])
              );
            }
            historyArray.push(historyObject);
          }
        });
        updatedLinkedComms = [...resultArray, ...linkedComments];
        updatedHistory = [...historyArray, ...history];
      } else {
        selectedRows.forEach((row) => {
          if (!row.original.isForeign) {
            resultArray.push(row.original.id);
            historyObject = {
              time: time,
              editor: user,
              action: `unlinked the comment -  `,
              value: row.original.id,
            };
          } else {
            foreignArray.push({
              commentId: row.original.id,
              remove: parentId,
            });
            historyObject = {
              time: time,
              editor: user,
              action: `unlinked the comment -  `,
              value: parentId,
            };
          }
        });

        updatedLinkedComms = linkedComments.filter(
          (linked) => !resultArray.includes(linked.id)
        );
        historyArray.push(historyObject);
        updatedHistory = [...historyArray, ...history];
      }

      setLinkedComments(updatedLinkedComms);
      setHistory(updatedHistory);
      updateMetaComment(updatedLinkedComms, updatedHistory);
      if (foreignArray.length) {
        dispatchStore(
          setCommentUnlinkings([...foreignUnlinks, ...foreignArray])
        );
      }
    }
  };

  const flattenComments = (reportData) => {
    const allComments = reportData.projects.flatMap((project) =>
      project.categories.flatMap((category) =>
        category.comments.filter(
          (comment) => comment.header.value != "" && comment.active
        )
      )
    );
    return allComments;
  };

  const filterInitialState = {
    isOpen: {
      id: false,
      header: false,
      type: false,
      author: false,
      rag: false,
    },
    commentIds: [],
    authors: [],
    projects: [],
    headers: [],
    types: ["Cost", "Time", "Scope", "Stakeholders", "Approvals"],
    rags: ["Green", "Amber", "Red"],
    id: [],
    author: [],
    header: [],
    dateFrom: null,
    dateTo: null,
    periodFrom: null,
    periodTo: null,
    reportStatuses: ["Draft", "Under Review", "Completed"],
    project,
    linkingStatuses:
      type === "note"
        ? ["Related", "None"]
        : ["Related", "Parent", "Child", "None"],
  };

  const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
      case "SET_IS_OPEN":
        return {
          ...state,
          isOpen: {
            ...state.isOpen,
            [payload.type]: payload.value,
          },
        };
      case "RESET_FILTERS":
        const {
          authors,
          projects,
          headers,
          commentIds,
          ...filteredInitialState
        } = filterInitialState;
        return {
          ...state,
          ...filteredInitialState,
        };

      default:
        return { ...state, [type]: payload };
    }
  };
  const [filterState, dispatch] = useReducer(reducer, filterInitialState);
  const {
    isOpen,
    commentIds,
    authors,
    projects,
    headers,
    types,
    rags,
    id,
    author,
    project,
    header,
    dateFrom,
    dateTo,
    periodFrom,
    periodTo,
    reportStatuses,
    linkingStatuses,
  } = filterState;

  const fetchData = async (reportComments, linkedItems) => {
    try {
      const modifiedItems = reportComments.map((item) => {
        const category = Cts.categoriesLocal.find((c) => c.id === item.catId);
        const catName = category ? category.name : "";
        let linkStatus = linkedItems?.some((linked) => linked.id === item.id)
          ? linkedItems.find((linked) => linked.id === item.id).type ===
            "parent"
            ? "Parent"
            : linkedItems.find((linked) => linked.id === item.id).type ===
              "child"
            ? "Child"
            : "Related"
          : item.linkedComms?.some((linked) => linked.id === parentId) &&
            !foreignUnlinks?.some((ul) => ul.remove === parentId)
          ? "Related"
          : "None";
        if (type === "note") {
          linkStatus = linkedComments.includes(item.id) ? "Related" : "None";
        }
        return {
          id: item.id,
          header: item.header.value,
          type: catName,
          author: item.author || "Hari Narayn",
          rag: item.rag.label === "Orange" ? "Amber" : item.rag.label,
          description: item.desc,
          linked: linkStatus,
          status: item.reportStatus,
          period: item.reportPeriod,
          project: item.projectName,

          isForeign: item.linkedComms?.some((linked) => linked.id === parentId),

          created: moment(
            (item.created && item.created.split(" ")[0]) || "01/06/2023",
            "D/M/YYYY"
          )?.format("DD/MM/YYYY"),
          projectName: item.projectName,
          reportTitle: item.reportTitle,
        };
      });
      setComments(modifiedItems);
      setFilterData(modifiedItems);
    } catch (error) {
      console.error("Error while retrieving comments:", error);
    }
  };

  const setFilterData = (items) => {
    const headers = setUnique(items, "header");
    dispatch({ type: "headers", payload: headers });

    const commentIds = sortAlpha(setUnique(items, "id"));
    dispatch({ type: "commentIds", payload: commentIds });

    const authors = setUnique(items, "author");
    dispatch({ type: "authors", payload: sortAlpha(authors) });

    const projects = setUnique(items, "project");
    dispatch({ type: "projects", payload: sortAlpha(projects) });
  };

  const data = useMemo(() => comments, [comments]);
  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 300,
      maxWidth: 400,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setFilter,
    state,
    setGlobalFilter,
    setAllFilters,
    toggleAllRowsSelected,
    toggleRowSelected,
  } = useTable(
    {
      columns,
      initialState: {
        hiddenColumns: columns.map((column) => {
          if (column.show === false) return column.accessor || column.id;
        }),
        sortBy: [
          {
            id: "created",
            desc: true,
          },
        ],
      },
      data,
      defaultColumn,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useBlockLayout,
    useResizeColumns,
    useRowSelect
  );

  const { globalFilter } = state;

  const handleSearch = (e) => {
    setGlobalFilter(e.target.value || undefined);
  };

  const handleHide = (accessor) => {
    setColumns((prevColumns) =>
      prevColumns.map((column) =>
        column.accessor === accessor
          ? { ...column, show: !column.show }
          : column
      )
    );
  };

  const handleRangeValues = (value, prevFilters, isChecked) => {
    const [start, end] = value.split("-");
    const lowerBound = parseInt(start);
    const upperBound = parseInt(end);
    const rangeValues = [];

    for (let i = lowerBound; i <= upperBound; i++) {
      rangeValues.push(i.toString());
    }

    return isChecked
      ? [...prevFilters, ...rangeValues]
      : prevFilters.filter((value) => !rangeValues.includes(value));
  };

  const handleSelect = (columnId, e) => {
    const filters = (prevFilters = []) => {
      const value = e.target.value;
      const isChecked = e.target.checked;

      if (value.includes("-")) {
        return handleRangeValues(value, prevFilters, isChecked);
      }

      return isChecked
        ? [...prevFilters, value]
        : prevFilters.filter((filter) => filter !== value);
    };

    setFilter(columnId, filters);
    dispatch({ type: columnId, payload: filters });
  };

  const handleDateToChange = (columnId, colPart, from, date) => {
    dispatch({ type: colPart, payload: date });
    setFilter(columnId, [from, date]);
  };

  const handleDateChange = (columnId, colPart, colToPart, date) => {
    dispatch({ type: colPart, payload: date });
    if (colToPart != null) {
      dispatch({ type: colToPart, payload: null });
      setFilter(columnId, "");
    }
    setFilter(columnId, [date]);
  };

  const handleMultiChange = (selOptions, field) => {
    dispatch({ type: field, payload: selOptions });
    const values = selOptions.map((item) => item.value);
    setFilter(field, values);
  };
  const handleClearFilters = () => {
    setAllFilters([]);
    dispatch({ type: "RESET_FILTERS" });
    setGlobalFilter("");
  };

  const filterData = [
    {
      accessor: "linked",
      label: "Link Type",

      type: "linked",
      data: linkingStatuses,
      control: "checkLegend",
    },
    {
      accessor: "id",
      label: "ID",
      type: "id",
      data: commentIds,
      control: "select",
      name: "filtersId",
      placeholder: "Select Report Id/s...",
      styles: finderSelectStyles,
      value: id,
    },
    {
      accessor: "header",
      label: "Package",
      type: "header",
      data: headers,
      control: "select",
      name: "filtersHeader",
      placeholder: "Select Header/s...",
      styles: finderSelectStyles,
      value: header,
    },
    {
      accessor: "rag",
      label: "RAG",
      type: "rag",
      data: rags,
      control: "checkRag",
    },
    {
      accessor: "created",
      label: "Date Created",
      type: "created",
      control: "date",
      start: dateFrom,
      startString: "dateFrom",
      end: dateTo,
      endString: "dateTo",
    },
    {
      accessor: "author",
      label: "Author",
      type: "author",
      data: authors,
      control: "select",
      name: "filtersAuthor",
      placeholder: "Select Author/s...",
      styles: finderSelectStyles,
      value: author,
    },
    {
      accessor: "period",
      label: "Reporting Period",
      type: "period",
      control: "date",
      start: periodFrom,
      startString: "periodFrom",
      end: periodTo,
      endString: "periodTo",
    },
    {
      accessor: "status",
      label: "Report Status",
      type: "period",
      data: reportStatuses,
      control: "check",
    },
    {
      accessor: "type",
      label: "Category",
      type: "type",
      data: types,
      control: "check",
    },
    {
      accessor: "project",
      label: "Project",
      type: "project",
      data: projects,
      control: "select",
      name: "filtersProject",
      placeholder: "Select Project/s...",
      styles: finderSelectStyles,
      value: project,
    },
  ];

  return (
    comments && (
      <Modal
        dialogClassName={linkerStyles.cmdModalArea}
        show={show}
        onHide={handleModalClose}
      >
        <Modal.Header closeButton>
          <>
            <label className={linkerStyles.topHeaderCC}>
              <DatasetLinkedTwoToneIcon className={linkerStyles.iconHead} />
              {view && <span className={linkerStyles.viewLabel}>View</span>}
              {type === "note"
                ? ` Link Comments for the ${type}`
                : ` Link Comments to`}
              : {parentId} -{" "}
              {title.length > 14 ? `${title.slice(0, 14)}...` : title}
            </label>
          </>
        </Modal.Header>
        <Modal.Body>
          {comments.length === 0 ? (
            type === "note" ? (
              <div>No comments available for linking. Please add a comment</div>
            ) : (
              <CircularProgress
                className={linkerStyles.customCircularProgress}
              />
            )
          ) : (
            <>
              <div className={linkerStyles.container}>
                <div className={linkerStyles.gridContainer}>
                  <div className={linkerStyles.leftSection}>
                    <div
                      className={finderStyles.clearFiltersContainer}
                      onClick={handleClearFilters}
                    >
                      <BiReset className={finderStyles.clearFiltersIcon} />
                      <span className={finderStyles.clearFiltersTextComment}>
                        Reset Filters
                      </span>
                    </div>
                    <div className={finderStyles.filterSection}>
                      {filterData.map((filter) => {
                        return (
                          <div
                            className={finderStyles.filter}
                            key={filter.accessor}
                          >
                            {columns.find(
                              (column) => column.accessor === filter.accessor
                            )?.show ? (
                              <>
                                {isOpen[filter.type] ? (
                                  <RiArrowDownSLine
                                    className={finderStyles.iconFilter}
                                    onClick={() =>
                                      dispatch({
                                        type: "SET_IS_OPEN",
                                        payload: {
                                          type: filter.type,
                                          value: false,
                                        },
                                      })
                                    }
                                  />
                                ) : (
                                  <RiArrowRightSLine
                                    className={finderStyles.iconFilter}
                                    onClick={() =>
                                      dispatch({
                                        type: "SET_IS_OPEN",
                                        payload: {
                                          type: filter.type,
                                          value: true,
                                        },
                                      })
                                    }
                                  />
                                )}
                                <label className={finderStyles.lblFilter}>
                                  {filter.label}
                                </label>
                              </>
                            ) : (
                              <>
                                <RiArrowRightSLine
                                  className={finderStyles.iconFilterHide}
                                />
                                <label
                                  className={finderStyles.lblFilterDisabled}
                                >
                                  {filter.label}
                                </label>
                              </>
                            )}

                            {isOpen[filter.type] ? (
                              <div className={finderStyles.filterContent}>
                                {filter.control === "check" &&
                                  filter.data.map((val) => (
                                    <Form.Check
                                      type="checkbox"
                                      label={val}
                                      value={val}
                                      id={val}
                                      onClick={(e) =>
                                        handleSelect(filter.accessor, e)
                                      }
                                    />
                                  ))}
                                {filter.control === "checkRag" &&
                                  filter.data.map((val) => (
                                    <div className={finderStyles.ragFilter}>
                                      <Form.Check
                                        type="checkbox"
                                        label={val}
                                        value={val}
                                        id={val}
                                        onClick={(e) =>
                                          handleSelect(filter.accessor, e)
                                        }
                                      />
                                      <BoxHeader backgroundColor={val} />
                                    </div>
                                  ))}
                                {filter.control === "checkLegend" && (
                                  <div className={linkerStyles.legend}>
                                    {filter.data.map((val) => (
                                      <div
                                        className={linkerStyles.legendItem}
                                        key={val}
                                      >
                                        <Form.Check
                                          type="checkbox"
                                          label={val}
                                          value={val}
                                          id={val}
                                          onClick={(e) =>
                                            handleSelect(filter.accessor, e)
                                          }
                                        />

                                        <div
                                          className={linkerStyles.legendLink}
                                          style={{
                                            backgroundColor:
                                              getColorForVal(val),
                                          }}
                                        ></div>
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {filter.control === "date" && (
                                  <div
                                    className={finderStyles.datepickerWrapper}
                                  >
                                    <DatePicker
                                      selected={filter.start}
                                      onChange={(date) =>
                                        handleDateChange(
                                          filter.accessor,
                                          filter.startString,
                                          filter.endString,
                                          date
                                        )
                                      }
                                      className={finderStyles.datePicker}
                                      dateFormat="dd/MM/y"
                                      placeholderText="Pick date..."
                                    />

                                    <label>To</label>
                                    <DatePicker
                                      selected={filter.end}
                                      onChange={(date) =>
                                        handleDateToChange(
                                          filter.accessor,
                                          filter.endString,
                                          filter.start,
                                          date
                                        )
                                      }
                                      minDate={filter.start}
                                      dateFormat="dd/MM/y"
                                      placeholderText="Pick date..."
                                      disabled={filter.start === null}
                                      className={finderStyles.datePicker}
                                    />
                                  </div>
                                )}
                                {filter.control === "select" && (
                                  <Select
                                    name={filter.name}
                                    placeholder={filter.placeHolder}
                                    styles={filter.styles}
                                    value={filter.value}
                                    options={filter.data}
                                    onChange={(selOptions) =>
                                      handleMultiChange(
                                        selOptions,
                                        filter.accessor
                                      )
                                    }
                                    isMulti
                                  />
                                )}
                              </div>
                            ) : columns.find(
                                (column) => column.accessor === filter.accessor
                              )?.show ? (
                              <VisibilityOn
                                onClick={() => handleHide(filter.accessor)}
                                className={finderStyles.iconColsShow}
                              />
                            ) : (
                              <VisibilityOff
                                onClick={() => handleHide(filter.accessor)}
                                className={finderStyles.iconColsHide}
                              />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={linkerStyles.rightSection}>
                    <div className={linkerStyles.topSection}>
                      <div
                        className={`${linkerStyles.searchBox} ${
                          type === "comment" ? linkerStyles.searchBoxCC : ""
                        }`}
                      >
                        <Form.Control
                          type="text"
                          placeholder=""
                          value={globalFilter || ""}
                          onChange={handleSearch}
                          className={finderStyles.searchControl}
                        />
                      </div>

                      <div className={linkerStyles.buttons}>
                        <button
                          className={`${linkerStyles.button} ${
                            linkerStyles.confirmButton
                          } ${
                            rows.some(
                              (row) =>
                                row.isSelected && row.original.linked === "None"
                            )
                              ? ""
                              : linkerStyles.btnDisabled
                          }`}
                          onClick={() => handleUpdate(true)}
                        >
                          <MdLink className={linkerStyles.buttonIcon} />
                          Link
                        </button>

                        <button
                          className={`${linkerStyles.button} ${
                            linkerStyles.resetButton
                          } ${
                            rows.some(
                              (row) =>
                                row.isSelected &&
                                row.original.linked === "Related"
                            )
                              ? ""
                              : linkerStyles.btnDisabled
                          }`}
                          onClick={() => handleUpdate(false)}
                        >
                          <MdLinkOff className={linkerStyles.buttonIcon} />
                          Unlink
                        </button>
                      </div>
                    </div>

                    <div
                      className={
                        type === "note"
                          ? linkerStyles.countSectionNC
                          : linkerStyles.countSectionCC
                      }
                    >
                      {rows.length === 1
                        ? `${rows.length} Result`
                        : `${rows.length} Results`}
                    </div>
                    {type === "note" && (
                      <div className={linkerStyles.selectAllCheckbox}>
                        <Form.Check
                          type="checkbox"
                          onClick={handleSelectAllRows}
                          checked={
                            rows.length > 0 &&
                            rows.every((row) => row.isSelected)
                          }
                          custom
                          label=""
                        />
                        <span>Select All</span>
                      </div>
                    )}

                    <div
                      className={`${linkerStyles.bottomSection} ${
                        view ? linkerStyles.viewOnly : ""
                      }`}
                    >
                      <table {...getTableProps()} className={finderStyles.grid}>
                        <thead>
                          {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                              {headerGroup.headers.map((column) => (
                                <th
                                  {...column.getHeaderProps(
                                    column.getSortByToggleProps()
                                  )}
                                >
                                  <CustomTooltip title="Click to sort">
                                    {column.render("Header")}
                                  </CustomTooltip>

                                  {column.isSorted ? (
                                    column.isSortedDesc ? (
                                      <HiSortDescending
                                        className={finderStyles.sortIcon}
                                      />
                                    ) : (
                                      <HiSortAscending
                                        className={finderStyles.sortIcon}
                                      />
                                    )
                                  ) : (
                                    ""
                                  )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        {comments.length > 0 ? (
                          <tbody
                            className={finderStyles.reportContent}
                            {...getTableBodyProps()}
                          >
                            {rows.map((row, index) => {
                              prepareRow(row);
                              const rowId = row.original.id;
                              const isCommentLinked = linkedComments?.some(
                                (linked) => linked.id === rowId
                              );
                              // linkedComments?.includes(
                              //   row.original.id
                              // );
                              const rowClassName =
                                linkerStyles.tableRowWithBorder;
                              const cid = row.original.id;
                              return (
                                <tr
                                  {...row.getRowProps()}
                                  className={rowClassName}
                                  style={{
                                    backgroundColor: getColorForVal(
                                      row.original.linked
                                    ),
                                  }}
                                >
                                  {row.cells.map((cell) => (
                                    <td {...cell.getCellProps()}>
                                      {cell.render("Cell")}
                                    </td>
                                  ))}
                                </tr>
                              );
                            })}
                          </tbody>
                        ) : (
                          <div className={finderStyles.loaderOverlay}>
                            <div className={finderStyles.loaderContainer}>
                              <ThreeDots
                                color="#186d90"
                                height={150}
                                width={150}
                              />
                            </div>
                          </div>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    )
  );
}
